<template>
    <div class="audit-page">
        <div class="audit-main-img"></div>
        <div class="audit-main-des">
            
            <div class='audit-main-text'>恭喜您已完成信息认证，您可以</div>
            <div class='audit-main-text'>去申请借款了</div>
        </div>
        <div class="audit-main-btn" @click="goIndex">完成</div>
         <div class="i-hot-product">
    <div class='i-hot-header'>
      <div class='i-hot-right'>为你推荐</div>
    </div>
    <!-- 卡片 -->
    <div class='i-hot-card' v-for="item in productList" :key='item.prodCode' @click="proItem(item)">
      <div class='i-hot-card-header'>
        <div >
          <img class="i-hot-card-img" :src="item.prodIcon" alt='产品图'/>
        </div>
        <div class='i-hot-card-text'>{{item.prodName}}</div>
      </div>
      <div class='i-hot-card-moeny'>
        <div class='i-hot-card-number'>{{item.totalCreditAmt}}</div>
        <div class='i-hot-card-btn'>立即借钱</div>
      </div>
      <div class="i-hot-card-des">
        <div class="i-hot-card-foo" v-if='item.slogans.length==1'>{{item.slogans[0]}}</div>
        <div class='i-hot-card-footer' v-else>
          <div class='i-hot-footer-right'>
             {{item.slogans[0]}}
            <!-- <span>利率：</span>
            <span>0.04%/日</span> -->
          </div>
          <div class='i-hot-footer-left'>
            {{item.slogans[1]}}
            <!-- <span>期限：</span>
            <span>30-360日</span> -->
          </div>
        </div>
        
      </div>
    </div>
  </div>  
    </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import { onMounted, toRefs,reactive } from "vue"
import APP from '../../utils/APP'
import API from '../../server/api'
import {routerTag } from '../../utils/index'

export default ({
    setup() {
        const state=reactive({
            checked:false,//协议选中
            productList:[],//产品
        })
        const proInit= async ()=>{
          const result= await API.loanList({"type":"1"})
          state.productList=result.productList
          // console.log(result,8865443);
        }
        const goIndex=()=>{
          window.TDAPP.onEvent("认证完成页-点击完成-进入");
          APP.JUMP_H5(routerTag)
        }
        const proItem=(item)=>{
          window.localStorage.setItem('appNo',item.appNo)
          APP.JUMP_H5(item.button.url)
        }
        onMounted(()=>{
         
          APP.SET_TITLE('认证完成')
          APP.IS_SHOW_BACK(false)
          proInit()
        })
        return{
            ...toRefs(state),
            goIndex,
            proItem
        }
    },
})
</script>